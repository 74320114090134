import React from 'react';
import Modal from 'react-modal';
import Search from "./search";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faTimesCircle } from "@fortawesome/free-solid-svg-icons";

Modal.setAppElement('#___gatsby')
class ModalWindow extends React.Component {
  constructor() {
    super();
    this.state = {
      modalIsOpen: false
    };
    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
  }
  openModal() {
    this.setState({modalIsOpen: true});
  }
  closeModal() {
    this.setState({modalIsOpen: false});
  }
  render() {
    return (
      <div>
        <FontAwesomeIcon icon={faSearch} onClick={this.openModal} />
        <Modal
          isOpen={this.state.modalIsOpen}
          onRequestClose={this.closeModal}
          contentLabel="Seach Modal"
          className="modalSearchWindow"
          overlayClassName="modalSearchOverlay"
        >
          <div className="closeButton"><FontAwesomeIcon icon={faTimesCircle} onClick={this.closeModal} /></div>
          <Search />
        </Modal>
      </div>
    );
  }
}
export default ModalWindow;