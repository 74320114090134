import * as React from "react"
import { Link } from "gatsby"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUserCircle, faFeatherAlt, faTags } from "@fortawesome/free-solid-svg-icons"
import ModalSeach from "../components/modal-search"

const Layout = ({ location, title, children }) => {
  const rootPath = `${__PATH_PREFIX__}/`
  const isRootPath = location.pathname === rootPath
  let header

  if (isRootPath) {
    header = (
      <div className="main-heading">
         <div><h1>{title}</h1></div>
         <div><ModalSeach /></div>
      </div>
    )
  } else {
    header = (
      <div className="header-link-home" >
        <div><Link to="/">{title}</Link></div>
        <div><ModalSeach /></div>
      </div>
    )
  }

  return (
    <div className="global-wrapper" data-is-root-path={isRootPath}>
      <header className="global-header">{header}<hr /></header>
      <main>{children}</main>
      <footer className="global-footer">
        <div>© {new Date().getFullYear()} jack-s</div>
        <div><Link to="/"><FontAwesomeIcon icon={faFeatherAlt} /> Posts</Link></div>
        <div><Link to="/tags/"><FontAwesomeIcon icon={faTags} /> Tags</Link></div>
        <div><Link to="/profile/"><FontAwesomeIcon icon={faUserCircle} /> Profile</Link></div>
      </footer>
    </div>
  )
}

export default Layout
